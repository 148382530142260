window.selectTab = (tab, link) => {
  if(tab === pagination.tabId){
    return;
  }
  pagination = {
    ...pagination,
    tabId: tab,
    page: 1,
    link: link
  };
  toggleLoading(true);
  updateBreadCrumbs(tab, link);
  chengeSelectedTab(tab, link);
  emptyPreviousResults();
  fetch(`/api${link}?pageSize=${pagination.limit}&page=${pagination.page}`)
  .then(response => response.json())
  .then(response => {
    toggleLoading(false);
    const projects = response.model.Articles?.items;
    if(!projects?.length){
      $("#recent-work-no-results").removeClass('hidden');
    } else {
      $("#recent-work-no-results").addClass('hidden');
      drawProjects(projects);
      handlePagination(response.model.Articles?.hasMorePages);
    }
  })
  .catch(error => {
    $("#recent-work-loading").addClass('hidden');
    $("#recent-work-no-results").removeClass('hidden');
  });
}

window.loadMore = () => {
  pagination.page++;
  toggleLoading(true);
  setTimeout(() => {
    fetch(`/api${pagination.link}?pageSize=${pagination.limit}&page=${pagination.page}`)
    .then(response => response.json())
    .then(response => {
      toggleLoading(false);
      const projects = response.model.Articles?.items;
      if(!projects?.length){
       return;
      }
      drawProjects(projects);
      handlePagination(response.model.Articles?.hasMorePages);
    })
    .catch(error => {
      $("#recent-work-loading").addClass('hidden');
    });
  }, 1000);
}

window.toggleLoading = (state) => {
  if(state){
    $("#recent-work-load-more").addClass("hidden");
    $("#recent-work-loading").removeClass('hidden');
  } else {
    $("#recent-work-loading").addClass('hidden');
  }
}

window.getCategory = (token, isForUrl = true) => {
  if(tabs.some(tab => token === tab.link.split("/")[tab.link.split("/").length-1])){
    return token
  } else {
    return isForUrl ? null : 'ALL';
  }
}

window.updateBreadCrumbs = (tab, link) => {
  const tokens = link.split("/");
  const category = getCategory(tokens[tokens.length - 1], false);
  $(".our-work-header-bredcrumb a:last-of-type").remove();
  $(".our-work-header-bredcrumb").append(`<a>${category}</a>`);
}

window.chengeSelectedTab = (tab, link) => {
  $('.our-work-tab').removeClass('selected');
  $(`#our-work-${tab}`).addClass('selected');
  const category = getCategory(link.split("/")[link.split("/").length-1]);
  let url = pagination.baseUrl;
  if(category){
    url += `?category=${category}`;
  }
  window.history.pushState({}, '', url);
}

window.emptyPreviousResults = () => {
  $("#recent-work-body .recent-work").remove();
  if(!$("#recent-work-no-results").hasClass('hidden')){
    $("#recent-work-no-results").addClass('hidden');
  }
}

window.drawProjects = (projects) => {
  projects.forEach(project => {
    const projectDiv = `
      <div class="recent-work">
        <img  class="recent-work-image" src="${project.featured_image.fullUrl}" />
        <div class="recent-work-title" onclick="window.openSlideshow('${encodeURIComponent(JSON.stringify(project.dynamicGalleries))}',0,true)" >
        <a class="recent-work-title" href="${project.slug}">
          ${project.title}
        </a>
        </div>
      </div>
    `;
    $( projectDiv ).insertBefore( "#recent-work-no-results" );
  });
}

window.handlePagination = (hasMoreages) => {
  if(!hasMoreages){
    if(!$("#recent-work-load-more").hasClass("hidden")){
          $("#recent-work-load-more").addClass("hidden");
        }
  } else {
    $("#recent-work-load-more").removeClass("hidden");
  }
}

